var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('l-button',{attrs:{"type":"default","wide":"","outline":""},on:{"click":function($event){return _vm.onBack()}}},[_c('i',{staticClass:"nc-icon left-arrow"}),_vm._v(" Ana səhifə ")])],1),_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Şifrəni dəyiş")])])])])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"pl-lg-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('ValidationProvider',{attrs:{"name":"oldpassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Old password field is required' : null,"hasSuccess":passed,"label":"Old password","placeholder":"Old password","name":"Old password"},model:{value:(_vm.pwd.old),callback:function ($$v) {_vm.$set(_vm.pwd, "old", $$v)},expression:"pwd.old"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('ValidationProvider',{attrs:{"name":"newpassword","rules":_vm.pwdrule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The New password field is required' : null,"hasSuccess":passed,"label":"New password","placeholder":"New password","name":"New password"},model:{value:(_vm.pwd.new),callback:function ($$v) {_vm.$set(_vm.pwd, "new", $$v)},expression:"pwd.new"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('ValidationProvider',{attrs:{"name":"repassword","rules":{
                      is: _vm.pwd.new,
                      required: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
                    var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed
                          ? 'The Retype password field is required and must match New password'
                          : null,"hasSuccess":passed,"label":"Retype password","placeholder":"Retype password","name":"Retype password"},model:{value:(_vm.pwd.renew),callback:function ($$v) {_vm.$set(_vm.pwd, "renew", $$v)},expression:"pwd.renew"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('l-button',{attrs:{"block":"","type":"primary"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.onSave)}}},[_vm._v("Yadda saxla")])],1)])])])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }