<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <card>
      <div class="row align-items-center">
        <div class="col-12">
          <l-button type="default" wide outline @click="onBack()">
            <i class="nc-icon left-arrow"></i>

            Ana səhifə
          </l-button>
        </div>
        <div class="col-12">
          <h3 class="mb-2">Kateqoriyalar</h3>
        </div>
        
      </div>
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="pl-lg-0">
            <button class="btn btn-fill btn-info btn-wd" @click="onNew()">
              Yeni kateqoriya
            </button>
          </div>

          <el-input
            type="search"
            class="mb-3"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          />
        </div>
        <div class="col-sm-12">
          <el-table
            stripe
            style="width: 100%"
            :data="
              cats.filter(
                (c) =>
                  c.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                  -1
              )
            "
            border
          >
            <el-table-column :width="300" prop="name" label="Name">
            </el-table-column>
            <el-table-column prop="description" label="Təsvir">
            </el-table-column>
            <el-table-column :width="120" fixed="right" label="Actions">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Edit'"
                  class="btn-warning btn-simple btn-link"
                  @click="onEdit(props.row)"
                  ><i class="nc-icon d-edit"></i> </a
                >&nbsp;&nbsp;
                <a
                  v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                  @click="onDelete(props.row)"
                  ><i class="nc-icon d-remove"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog center title="Kateqoriya action" :visible.sync="catModal">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="row">
            <div class="col-lg-12">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="text"
                  :error="failed ? 'The Kateqoriya Name field is required' : null"
                  :hasSuccess="passed"
                  label="Kateqoriya name"
                  placeholder="Kateqoriya name"
                  v-model="cat.name"
                  name="Kateqoriya name"
                ></fg-input>
              </ValidationProvider>
            </div>

            <div class="col-lg-12">
              <fg-input label="Təsvir">
                <textarea
                  v-model="cat.description"
                  class="form-control"
                  placeholder="Kateqoriya decription"
                  rows="3"
                ></textarea>
              </fg-input>
            </div>
          </div>

          <div slot="footer" class="dialog-footer text-center">
            <button class="btn btn-danger" @click="catModal = false">
              Xitam</button
            >&nbsp;
            <button class="btn btn-success" @click="handleSubmit(onSave)">
              Yadda saxla
            </button>
          </div>
        </ValidationObserver>
      </el-dialog>
    </card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { CategoryType } from "../../services/types";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  MessageBox,
} from "element-ui";
import {
  GetCategories,
  DeleteCategory,
  UpdateCategory,
  CreateCategory,
} from "../../services/know";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    Loading,
  },
  data() {
    return {
      searchQuery: "",
      isLoading: false,
      cats: [],
      catModal: false,
      cat: {},
      selectedcat: "",
    };
  },
  methods: {
    onBack() {
      this.$router
        .push({
          path:
            this.$store.state.user.roleid === 1
              ? "/admin/main"
              : "/account/main",
        })
        .catch((e) => {});
    },
    showSwal(type) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to recover this record!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      }
    },
    onNew() {
      this.cat = new CategoryType();
      this.cat.uid = this.$store.state.user.id;
      this.catModal = true;
    },
    onEdit(item) {
      this.cat = Object.assign({}, item);
      this.catModal = true;
    },
    onDelete(item) {
      this.showSwal("warning").then((del) => {
        if (del.isConfirmed) {
          this.isLoading = true;
          DeleteCategory(item.id, this.$store.state.user.id).then(
            (response) => {
              if (response.data.result) {
                this.isLoading = false;
                this.getCategories();
                this.notifyVue("success", "Changes have been saved!");
              } else {
                this.isLoading = false;
                this.notifyVue("danger", response.data);
              }
            }
          );
        }
      });
    },
    onSave() {
      this.isLoading = true;
      if (!this.cat.id) {
        CreateCategory(this.cat).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.catModal = false;
            this.getCategories();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      } else {
        UpdateCategory(this.cat).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.catModal = false;
            this.getCategories();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      }
    },
    getCategories() {
      this.isLoading = true;
      GetCategories(this.$store.state.user.id).then((response) => {
        if (response.data.result !== undefined) {
          this.isLoading = false;
          this.cats = response.data.result;
        } else {
          this.isLoading = false;
          this.cats = [];
        }
      });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style lang="scss">
.el-dialog {
  width: 50%;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 90%;
  }
}
</style>