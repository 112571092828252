<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-12">
                <l-button type="default" wide outline @click="onBack()">
                  <i class="nc-icon left-arrow"></i>

                  Ana səhifə
                </l-button>
              </div>
              <div class="col-12">
                <h3 class="mb-0">Şifrəni dəyiş</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid mt--6">
          <div class="row">
            <div class="col-xl-12">
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <ValidationProvider
                      name="oldpassword"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="password"
                        :error="
                          failed ? 'The Old password field is required' : null
                        "
                        :hasSuccess="passed"
                        label="Old password"
                        placeholder="Old password"
                        v-model="pwd.old"
                        name="Old password"
                      ></fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <ValidationProvider
                      name="newpassword"
                      :rules="pwdrule"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="password"
                        :error="
                          failed ? 'The New password field is required' : null
                        "
                        :hasSuccess="passed"
                        label="New password"
                        placeholder="New password"
                        v-model="pwd.new"
                        name="New password"
                      ></fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <ValidationProvider
                      name="repassword"
                      :rules="{
                        is: pwd.new,
                        required: true,
                      }"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="password"
                        :error="
                          failed
                            ? 'The Retype password field is required and must match New password'
                            : null
                        "
                        :hasSuccess="passed"
                        label="Retype password"
                        placeholder="Retype password"
                        v-model="pwd.renew"
                        name="Retype password"
                      ></fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <l-button
                      block
                      type="primary"
                      @click.prevent="handleSubmit(onSave)"
                      >Yadda saxla</l-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </ValidationObserver>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

import { Encrypt } from "../services/crypto";
import { GetUser, Update, nophoto, ChangePassword } from "../services/user";
import store from "../store";
export default {
  props: {
    id: String,
  },
  data() {
    return {
      pwd: {
        new: "",
        old: "",
        renew: "",
      },
      pwdrule: {
        required: true,
        min: 5,
        max: 20,
      },
      status: "",
    };
  },
  methods: {
    onBack() {
      this.$router
        .push({
          path:
            this.$store.state.user.roleid === 1
              ? "/admin/main"
              : "/account/main",
        })
        .catch((e) => {});
    },
    onSave() {
      var pwd = {};
      pwd.id = this.$store.state.user.id;
      pwd.new = Encrypt(this.pwd.new, this.$store.state.user.pwdtoken).key;
      pwd.old = Encrypt(this.pwd.old, this.$store.state.user.pwdtoken).key;
      // console.log(pwd);
      ChangePassword(pwd).then((response) => {
        if (response.data.result.changed === "1") this.notifyVue("success");
        else this.notifyVue("danger");
      });
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
